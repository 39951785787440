<template>
  <div class="go-top" :class="show?'':'hide'">
      <i class="iconfont icon-huojian" @click="goTopHandler"></i>
  </div>
</template>

<script>
export default {
    data(){
        return {
            show: false
        }
    },
    created(){
        window.addEventListener('scroll',()=>{
             //变量scrollTop是滚动条滚动时，距离顶部的距离
            let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            if(scrollTop+windowHeight==scrollHeight){
                //写后台加载数据的函数
                this.$bus.$emit('loadMore')
            }

            if(scrollTop>100){
                this.show = true
            }else{
                this.show = false
            }
        })
    },
    methods:{
        goTopHandler(){
            this.show = false
            let timer = setInterval(()=>{
                let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                if(scrollTop == 0) clearInterval(timer)
                let top = scrollTop - Math.ceil(scrollTop/5)
                window.scrollTo(0,top)
            },80)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .go-top
        position fixed
        bottom 100px
        right 10vw
        z-index 9
        i
            font-size 46px
            position relative
            animation wait 0.8s ease 0s infinite alternate
        .arrow
            border-color transparent transparent red
            border-width 0px 17px 28px
            border-style solid
            height 0px
            width 0px
            border-radius 9px
            &:hover
                border-bottom-color #fb1010ad
    @keyframes wait {
        0% {
            top 0px
        }
        100% {
            top 6px
        }
    }
    .hide {
        bottom 100vh
        opacity 0
        transition bottom 2s, opacity 3s
    }
</style>