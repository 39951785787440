import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/view/home'
import Play from '@/view/play'
import More from '@/view/more'
import UrlPlay from '@/view/urlPlay'

Vue.use(VueRouter)

// 解决冗余导航报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/play',
    name: 'play',
    component: Play
  },
  {
    path: '/url_play',
    name: 'urlPlay',
    component: UrlPlay
  },
  {
    path: '/more',
    name: 'more',
    component: More
  },
  // 404页面
  {
    path: '*',  // * 表示上面路径匹配不到的都显示这个页面
    name: 'NotFoundComponent'
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
