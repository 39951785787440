<template>
    <div class="m-more">
        <div class="card m-shadow">
            <div class="card-header">
                <span></span>
            </div>
            <div class="card-body">
                <div class="row classify" v-for="(i, index) in classification" :key="index">
                    <span class="title">{{i.title}}</span>
                    <ul class="col-md-12 col-12">
                        <li v-for="(lable, index) in i.lable" :key="index" :class="{active: lable == '全部' ? query[i.key] == i.value[index]:classifyParams[i.key] == lable}" @click="classifyHandler(i.key, lable, i.value[index])">{{lable}}</li>
                    </ul>
                </div>
                <movie-list :movieList="movieList"></movie-list>
            </div>
        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination">
                <li class="page-item" @click="pagerHandler(query.page-1)">
                    <a class="page-link" :class="{prohibit: query.page<2}" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li class="page-item" v-for="(item, index) in pager" :key="index">
                    <a class="page-link" :class="{prohibit: query.page == item}" @click="pagerHandler(item)" href="#">{{item}}</a>
                </li>
                <li class="page-item" @click="pagerHandler(query.page+1)">
                    <a class="page-link" :class="{prohibit: query.page>Math.ceil(totle / query.pagesize)}" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { getMovie } from "@/api/movie";
import MovieList from "@/components/movieList"
export default {
    data(){
        return {
            query: {
                page: 1,
                pagesize: 20,
                columntype: '',
                releasedate: '',
                languagetype: ''
            },
            totle: 0,
            pager: [],
            movieList: [],
            classifyParams:{
                columntype: '',
                releasedate: '',
                languagetype: ''
            },
            name: '', // 片名
            classification:[
                {
                    title: '电　影',
                    key: 'columntype',
                    lable: ['全部','科幻片','喜剧片','爱情片','动作片','战争片','剧情片','恐怖片','纪录片',],
                    value: ['片','科幻片','喜剧片','爱情片','动作片','战争片','剧情片','恐怖片','纪录片',],
                },
                {
                    title: '电视剧',
                    key: 'columntype',
                    lable: ['全部','国产剧','韩剧','欧美剧','日剧','港剧','泰剧','台剧','海外剧','连续剧',],
                    value: ['剧','国产剧','韩%剧','欧美剧','日%剧','港剧','泰%剧','台%剧','海外剧','连续剧',]
                },
                {
                    title: '动　漫',
                    key: 'columntype',
                    lable: ['全部','国产','日韩','欧美','海外','港台','动漫电影',],
                    value: ['动漫','国产','日韩','欧美','海外','港台','动漫电影',]
                },
                {
                    title: '地　区',
                    key: 'languagetype',
                    lable: ['全部','大陆','香港','台湾','韩国','日本','印度','英国','美国','法国','俄罗斯','西班牙','加拿大','澳大利亚','泰国','意大利','新加坡',],
                    value: ['','大陆','香港','台湾','韩',  '日', '印度', '英国','美国','法', '俄罗斯','西班牙','加拿大','澳大利亚','泰',  '意大利','新加坡',]
                },
                {
                    title: '年　份',
                    key: 'releasedate',
                    lable: ['全部', ...new Array(9).fill(new Date().getFullYear()).map((x,i) => x-i)],
                    value: ['', ...new Array(9).fill(new Date().getFullYear()).map((x,i) => x-i)]
                },
            ]
        }
    },
    components: {MovieList},
    created(){
        document.title = '更多影片-五妖影院'
        this.searchInit()
    },
    methods:{
        getMovieList(){

            getMovie({name: this.name,...this.query}).then(res=>{
                this.movieList = res.data.data
                this.totle = res.data.totle
                this.pager = this.calcPage(this.query.page, this.query.pagesize, this.totle)
            })
        },
        pagerHandler(page){
            this.query.page = page
            this.getMovieList()
        },
        calcPage(page,pagesize,totle){
            let pager = []
            let totalPage = Math.ceil(totle / pagesize) || 1
            for (let i = page-2; i < page+5; i++) {
                if(i > totalPage || pager.length>=5) break
                if(i < 1) continue
                pager.push(i)
            }
            return pager
        },
        classifyHandler(key, lable, value){
            this.classifyParams[key] = lable
            this.query[key] = value
            this.getMovieList()
        },
        searchInit(){
            this.query.page = this.$route.query.page || 1
            this.query.pagesize = this.$route.query.pagesize || 20
            this.query.columntype = this.$route.query.columntype || ''
            this.query.languagetype = this.$route.query.languagetype || ''
            if(this.$route.query.key){
                // 筛选数据
                this.query.columntype = this.$route.query.key
            }
            if(this.$route.query.search){
                // 搜索数据
                this.name = this.$route.query.search
            }
            this.getMovieList()
        }
    },
    watch:{
        '$route.query':{
            deep: true,
            handler(x, y){
                this.searchInit()
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .classify
        position relative
        margin 8px 0
        .title
            position absolute
            top 2px
            display inline-block
            width 80px
            padding-right 3px
            margin-right 6px
            text-align right 
            font-weight 600
            border-right 3px solid
        ul
            margin 0px
            padding 0px
            li
                margin 0px 5px
                padding 2px 4px
                display inline-block
                cursor pointer
                &:hover,&.active
                    background-color #aaaaaa88
    @media (min-width: 768px)
        .classify
            ul
                padding-left 80px
    @media (max-width: 767px)
        .classify
            margin 0
            .title
                top 3px
                width 50px
                font-size 14px
            ul
                padding-left 50px
                li
                    margin 0px 2px
                    padding 1px 2px
                    font-size 12px
    .pagination
        margin-top -10px
        justify-content center
        .page-link
            opacity 0.9
            color unset
            background-color transparent
            &:focus
                box-shadow 0 0 0 2px rgba(255,255,255,.25)
        .prohibit
            pointer-events none
            cursor default
            opacity 0.6
            background-color #aaaaaa44
</style>