<template>
    <div class="home">
        <carousel :show-data="carouselData" @click-item="onClick" />
        <div class="card m-shadow" v-for="(it,index) in columnData" :key="index">
            <div class="card-header">
                <span>{{it.name}}</span>
                <span @click="$router.push({path:'/more', query:{key:it.key}})">查看更多</span>
            </div>
            <div class="card-body">
                <movie-list :movieList="it.movieList"></movie-list>
            </div>
        </div>
    </div>
</template>

<script>
import { getMovie } from "@/api/movie";
import MovieList from "@/components/movieList"
import Carousel from "@/components/carousel.vue"

export default {
    components: { MovieList, Carousel },
    data(){
        return{
            query: {
                page: 1,
                pagesize: 10
            },
            movieList: [],
            columnData: [
                {
                    name: '墙裂推荐',
                    key: '片',
                    movieList: []
                },
                {
                    name: '最新电视剧',
                    key: '剧',
                    movieList: []
                },
                {
                    name: '最新综艺',
                    key: '综艺',
                    movieList: []
                },
                {
                    name: '最新动漫',
                    key: '动漫',
                    movieList: []
                }
            ],
            carouselData: []
        }
    },
    created(){
        document.title = '五妖影院'
        this.getMovieList()
        this.getCarouselList()
    },
    methods:{
        getMovieList(){
            this.columnData.forEach(item=>{
                getMovie({columntype: item.key,...this.query}).then(res=>{
                    item.movieList = res.data.data
                })
            })
        },
        getCarouselList() {
            getMovie({ sourcetype: 1, page: 1, pagesize: 10 }).then(res => {
                this.carouselData = res.data.data
            })
        },
        onClick(data) {
            console.log('click')
            this.$router.push({
                path:'/play',
                query: { id: data.id }
            })
        }
    }
}
</script>

<style lang="stylus">
    .card-header
        display flex
        justify-content space-between
        background transparent
        :nth-child(2)
            font-size 14px
            opacity 0.5
            cursor pointer
</style>