<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="/">五妖影院</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="/">首页 <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#/url_play">解析播放</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li> -->
            </ul>
            <div class="form-inline my-2 my-lg-0">
                <input class="form-control mr-sm-2 h-75" type="search" v-model="searchKey" placeholder="请输入影片名" aria-label="Search" @keypress.enter="searchHandler">
                <button class="btn btn-outline-success my-2 my-sm-0 h-75" type="button" @click="searchHandler">搜索</button>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    data(){
        return {
            searchKey:'',
        }
    },
    methods: {
        searchHandler(){
            if(this.searchKey){
                this.$router.push({path: '/more', query:{search: this.searchKey}})
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .navbar
        color unset!important
        background-color tranparent
    .navbar-brand
        color unset!important
        font-weight 900
    .nav-link
        color unset!important
    .btn
        padding 2px 6px
</style>