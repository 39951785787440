<template>
  <div class="carousel-51">
    <div class="container-51">
      <div
        v-for="(ite, ind) in showData"
        :key="ind"
        class="carousel-51-item"
        @click="handleClick(ite)"
        v-lazy-container="{ selector: 'img' }"
      >
        <img :data-src="ite.filmpic + '?_=' + new Date() / 1" style="width: 100%; height: 100%" />
        <div class="title-51">{{ite.name}} <span>{{ite.updatetime.split(' ')[0]}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "carousel",
  props: {
    showData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timer: null
    };
  },
  watch: {
    // showData: {
    //   deep: true,
    //   immediate: true,
    //   handler(x, y) {
    //     if (x.length) {
    //       this.$nextTick(() => {
    //         this.calculateSize()
    //       })
    //     }
    //   }
    // }
  },
  created() {
    console.log(this.showData)
    this.$Lazyload.$on('loaded', ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }, formCache) => {
      this.calculateSize()
      this.scrollX()
    })
    this.$nextTick(() => {
      // 处理web端的鼠标移入事件
      const doc = document.querySelector('.carousel-51')
      doc.onmouseover = (e) => {
        this.timer && clearTimeout(this.timer)
      }
      doc.onmouseout = (e) => {
        this.scrollX()
      }
      // 处理移动设备的touch事件
      document.addEventListener('touchmove',(e) => {
        const str = JSON.stringify(e.target.classList) + JSON.stringify(e.target.offsetParent.classList)
        if (str.includes('carousel-51')) {
          this.timer && clearTimeout(this.timer)
        } else {
          this.scrollX()
        }
      })
    })
  },
  beforeDestory() {
    this.timer && clearTimeout(this.timer)
  },
  methods: {
    calculateSize() {
      this.$nextTick(() => {
        const doms = document.querySelectorAll('.carousel-51-item')
        let preW = 0 // 前一个宽度
        let preH = 0 // 前一个高度
        // let curW = 0 // 当前宽度
        // let curH = 0 // 当前高度
        let curTop = 0 // 当前顶部位置 定位
        let curLeft = 0 // 当前左边位置 定位
        let isNewCol = true // 是否需要新列展示
        doms.forEach((dom, index) => {
          const { naturalHeight, naturalWidth } = dom.querySelector('img')
          // 图片最大高度330，如果高度大于330的80%，强制变为330，否则就设置为自己的高度
          let height = naturalHeight >= 330 ? 330 : naturalHeight / 330 > 0.8 ? 330 : naturalHeight
          // 如果curTop不为0 宽度需与前一个相同
          let width = (curTop ? preW : 330 / naturalHeight * naturalWidth)
          // 如果curTop不为0 高度需为前一个与330的差
          dom.style.height = (curTop ? (330 - preH) : height) + 'px'
          dom.style.width = width + 'px'
          dom.style.left = curLeft + 'px'
          dom.style.top = curTop + 'px'
          // console.log(index, {preW, preH, curTop, curLeft, isNewCol, width, height})
          // 判断下一个 是否需要新列展示
          // 当前的top+当前的高度
          isNewCol = (curTop + height) / 330 > 0.8
          // top 定位
          // 1. 当前的 高度 >= 330 直接新一列显示
          // 2. 当前的 高度 > 330的80% 直接新一列显示
          // 3. 当前的需要新列显示
          curTop = height >= 330 ? 0 : height / 330 > 0.8 ? 0 : isNewCol ? 0 : (curTop + height)
          // left 定位 如果当前顶部不为0，则left与前一个相同
          curLeft += curTop ? 0 : width
          // 记录高度、宽度
          preH = height
          preW = width
        })
        // console.log(doms)
      })
    },
    handleClick(data) {
      this.$emit('click-item', data)
    },
    scrollX() {
      this.timer && clearTimeout(this.timer)
      const ele = document.querySelector('.carousel-51')
      if (!ele) return
      let left = ele.scrollLeft
      if (ele.scrollWidth - left <= ele.clientWidth + 5) {
        left = 0
      }
      this.timer = setTimeout(() => {
        ele.scrollLeft = left + 2
        this.scrollX()
      }, 18)
    }
  }
};
</script>

<style lang="stylus" scoped>
.carousel-51
  height 330px
  width 100%
  overflow-y hidden
  overflow-x scroll
  background-color #ffffff
  .container-51
    position relative
    height 330px
    width auto
    .carousel-51-item
      position absolute
      max-height 330px
      padding 3px
      .title-51
        position absolute
        bottom 0
        width calc(100% - 6px)
        height 26px
        line-height 26px
        text-align center
        background-color #00000088
        font-size 16px
        color #ffffff
        span
          font-size 12px
  &::-webkit-scrollbar
    height 4px
  &::-webkit-scrollbar-track
    background rgb(179, 177, 177, .5)
    border-radius 10px
  &::-webkit-scrollbar-thumb
    background rgb(136, 136, 136, .5)
    border-radius 10px
  &::-webkit-scrollbar-thumb:hover
    background rgb(100, 100, 100, .5)
    border-radius 10px
  &::-webkit-scrollbar-thumb:active
    background rgb(68, 68, 68, .5)
    border-radius 10px

</style>
