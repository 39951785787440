<template>
    <div class="plug">
        <!-- 灯 -->
        <div class="light">
            <!-- 灯的线 -->
            <span></span>
            <!-- 灯的图标 -->
            <i class="iconfont light-tip" :class="light?'icon-deng':'icon-light-off'" @click="lightSwich"></i>
            <!-- 播放历史 -->
            <div class="jilu" :style="light?'background:#000000;':'background:#ffffff; opacity:.8;'">
                <em class="iconfont icon-jilu1" @click="jiluHandler"></em>
                <div class="jilu-list" :style="light?'background:rgba(0, 0, 0, 0.733)':'background:rgba(255, 255, 255, 0.733)'">
                    <p>播放历史：</p>
                    <ul v-if="history.length>0">
                        <li v-for="(item,index) in history" :key="index" @click="$router.push({path:'/play', query: {id:item[1]}})"><i class="iconfont icon-jilu"></i>{{item[0]}}</li>
                    </ul>
                    <p v-else style="width:126px"><i class="iconfont icon-jilu"></i>暂无播放记录！</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['light'],
    data(){
        return {
            showJuji: false,
            history: []
        }
    },
    mounted(){
        this.getHistory()
        this.$bus.$on('getHistory',this.getHistory)
    },
    created() {
        // this.$nextTick(ele => {
        //     this.showTip()
        // })
    },
    methods:{
        getHistory(){
            this.history = JSON.parse(window.localStorage.getItem('history')) || []
        },
        lightSwich(){
            this.$emit('update:light',!this.light)
            document.querySelector('.light').className = 'light light-switch'
            setTimeout(()=>{
                document.querySelector('.light').className = 'light'
            },1000)
        },
        jiluHandler(e){
            if(document.querySelector('.jilu-list').classList.contains('showjilu')){
                document.querySelector('.jilu-list').classList.remove('showjilu')
                this.showJuji = false
                return
            }
            this.showJuji = !this.showJuji
            if(this.showJuji){
                document.querySelector('.jilu-list').classList.add('showjilu')
                document.addEventListener('click',this.fun)
            }else{
                document.removeEventListener('click',this.fun)
            }
        },
        fun(e){
            let flag = document.querySelector('.jilu-list').contains(e.target) || e.target == document.querySelector('.jilu em')
            if(flag) return
            document.querySelector('.jilu-list').classList.remove('showjilu')
            this.showJuji = false
        },
        // 展示页面功能提示
        showTip() {
            const winW = window.screen.availWidth
            const ele = document.querySelector('.light-tip')
            ele.style.display = 'inline-block'
            if (!ele.style.position) {
                ele.style.position = 'relative'
            }
            const width = ele.offsetWidth
            const tip = document.createElement('div')
            tip.style.right = width + 'px'
            tip.style.top = '20px'
            tip.innerText = '提示提示提示'
            tip.className = 'tip-51'
            ele.appendChild(tip)
        }
    }
}
</script>

<style lang="stylus">
.tip-51 {
    position: absolute;
    // top: 10px;
    // left: 10px;
    width: 120px;
    padding: 4px 10px;
    background-color: #ffffffee;
    box-shadow: 0 0 4px #999999;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
}
.plug{
    .light{
        margin-top -10px
        position fixed
        top 0
        right 4%
        z-index 1
        span{
            display block
            height 200px
            width 0
            margin 0 auto
            border 1px solid
            opacity 0.8
        }
        i{
            display block
            margin-top -10px
            font-size 28px
        }
        .jilu{
            width: 24px;
            height: 24px;
            position: absolute;
            bottom: 54px;
            left: 50%;
            transform: translateX(-50%);
            line-height 26px
            border-radius: 50%;
            cursor pointer
            .icon-jilu1{
                position absolute
                left -2px
                font-size 26px
            }
            .jilu-list{
                position absolute
                right 120%
                max-width 280px
                min-width 100px
                padding 4px 0
                border 1px solid
                border-radius 4px
                box-shadow 1px 1px 4px 1px
                transform scale(0)
                transform-origin right 10px
                transition transform 1s
                ul{
                    padding 0
                    margin 0
                    list-style none
                    li{
                        padding 0 6px
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                        &:hover{
                            background #99999999
                        }
                    }
                }
                P{
                    margin 0
                    padding 0 6px
                    font-size 14px
                }
                i{
                    display inline
                    font-size 14px
                    margin-right 2px
                }
            }
            .showjilu{
                transform: scale(1)
            }
        }
    }
    @media (max-width: 768px){
        .light {
            right 0%
        }
    }
    .light-switch {
        animation lightswitch 1s ease; 
    }
    @keyframes lightswitch {
        0 {
            margin-top -10px
        }
        50% {
            margin-top 0px
        }
        100% {
            margin-top -10px
        }
    }
}
</style>