<template>
    <div class="play m-shadow">
        <div class="player">
            <div class="frame-box">
                    <iframe id="frame" name="frame" 
                        :src="playUrl" 
                        marginwidth="0" 
                        marginheight="0" 
                        border="0" 
                        scrolling="no" 
                        frameborder="0" 
                        topmargin="0" 
                        width="100%" 
                        height="100%" 
                        allowfullscreen="true" 
                        mozallowfullscreen="true" 
                        msallowfullscreen="true" 
                        oallowfullscreen="true" 
                        webkitallowfullscreen="true"
                    ></iframe>
            </div>
            <div class="top-info" v-show="tipShow">
                <ul>
                    <li>{{rotationData[rotationShow-1]}}</li>
                    <li>{{rotationData[rotationShow]}}</li>
                </ul>
                <div class="top-close" @click="tipShow=false"><i class="iconfont icon-guanbi"></i></div>
            </div>
        </div>
        <div class="xianlu" v-if="jxShow">
            <span
                v-for='(item,index) in jxUrl'
                :class="index==jxActive?'active':''"
                :key="index"
                @click="changeXianlu(index)"
            >线路·0{{index+1}}</span>
        </div>
        <div class="juji">
            <div class="tip">需要播放的视频地址</div>
            <div class="url">
                <input type="text" v-model="url" placeholder="输入或粘贴需要播放的视频地址">
                <button @click="playHandler">播放</button>
            </div>
        </div>
        <div class="introduction">
            <div>提示</div>
            <p>1. 请不要相信视频中任何广告！</p>
            <p>2. 视频加载速度与网速有关，请耐心等待。</p>
            <p>3. 手机浏览器观看，将浏览器UA标识设置为“电脑”，可有效防止广告弹窗！</p>
        </div>
    </div>
</template>

<script>
import { getMovie, getJx } from "@/api/movie";
export default {
    data(){
        return{
            currentPlay: -1,
            playUrl: '',
            url: '',
            jxShow: true,
            jxUrl: [],
            jxActive: 0,
            rotationTimer: 0,
            rotationShow: 1,
            rotationData:[
                `当前播放：`,
                '请不要相信视频中任何广告！',
                '手机浏览器观看，将浏览器UA标识设置为“电脑”，可有效防止广告弹窗！',
                '视频加载速度与网速有关，请耐心等待'
            ],
            tipShow: true,
            showType: ''
        }
    },
    created(){
        // 小屏幕强制横屏
        // if(window.screen.width < window.screen.height){
        //     let box = document.querySelector('.player div')
        //     let width = box.offsetWidth
        //     let height = box.offsetHeight
        //     console.log(box, width, height)
        //     box.style.width = height+'px'
        //     box.style.height = width+'px'
        //     box.style.transform = 'rotate(90deg)'
        //     box.style.transformOrigin = `${width/2}px ${width/2}px`
        // }

        getJx().then(res=>{
            this.jxUrl = res.data[0].value.split(',')
        })

    },
    methods:{
        playHandler(){ // 切换 选集
            this.playUrl = this.jxUrl[this.jxActive] + this.url
			this.rotation()
        },
        changeXianlu(i){ // 切换 路线
            this.jxActive = i
            this.playUrl = this.jxUrl[this.jxActive] + this.url
        },
        rotation(){ // 文字轮播 方法
            this.rotationTimer = setInterval(()=>{
                document.querySelector('.top-info ul').style.transition ='margin 0.8s ease'
                document.querySelector('.top-info ul').style.marginTop ='-24px'
                setTimeout(() => {
                    document.querySelector('.top-info ul').style.marginTop ='0px'
                    document.querySelectorAll('.top-info ul li')[0].innerText = this.rotationData[this.rotationShow]
                    document.querySelector('.top-info ul').style.transition ='none'
                    this.rotationShow = this.rotationShow < this.rotationData.length-1 ? this.rotationShow+1 : 0
                    document.querySelectorAll('.top-info ul li')[1].innerText = this.rotationData[this.rotationShow]
                }, 800);
            }, 3000)
        }
    },
    beforeDestroy(){
        clearInterval(this.rotationTimer)
    }
}
</script>

<style lang="stylus" scoped>
    .play
        padding 10px 20px
        .title
            padding-top 20px
            font-size 18px
            font-weight 700
            opacity 0.8
        .player
            position relative
            margin 20px auto
            height 50vw
            max-height 600px
            background #000000
            overflow hidden
            .frame-box
                position relative
                height 100%
                iframe
                    width 100%
                    height 100%
            .top-info
                position absolute
                top 0px
                padding 0 10px
                width 100%
                height 22px
                overflow hidden
                background #ffffff66
                color #ffffff88
                z-index 9
                .top-close
                    position absolute
                    top 0px
                    right 10px
                    cursor pointer
                ul
                    margin 0
                    padding 0
                    li
                        list-style none
        .xianlu
            text-align center
            font-size 14px
            span
                display inline-block
                margin 4px 6px
                padding 2px 4px
                border 1px solid
                border-radius 4px
                filter invert(0%)
                cursor pointer
                &:hover,&.active
                    // filter invert(35%)
                    background-color #37ce4488!important
        .juji
            .tip
                border-left 4px solid
                padding-left 6px
                margin-bottom 10px
            .url
                display flex
                font-size 14px
                input
                    flex 1
                    color #666666
                button
                    width 60px
                    margin-left 20px
                    border-radius 4px
        .introduction
            padding 20px 0
            > div
                border-left 4px solid
                padding-left 6px
                margin-bottom 10px
            > p
                margin 0
                padding-left 10px
                font-size 13px
                line-height 20px
</style>