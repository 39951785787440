<template>
    <div class="play m-shadow">
        <div class="title">{{movieInfo.name}}</div>
        <div class="player">
            <div v-if="!ckPlayer" class="frame-box">
                    <iframe id="frame" name="frame" 
                        :src="playUrl" 
                        marginwidth="0" 
                        marginheight="0" 
                        border="0" 
                        scrolling="no" 
                        frameborder="0" 
                        topmargin="0" 
                        width="100%" 
                        height="100%" 
                        allowfullscreen="allowfullscreen" 
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
            </div>
            <player v-else :playurl="playUrl"></player>
            <div class="top-info" v-show="tipShow">
                <ul>
                    <li>{{rotationData[rotationShow-1]}}</li>
                    <li>{{rotationData[rotationShow]}}</li>
                </ul>
                <div class="top-close" @click="tipShow=false"><i class="iconfont icon-guanbi"></i></div>
            </div>
        </div>
        <div class="xianlu" v-if="jxShow">
            <div class="controller">
                <div>
                    <span
                        :class="currentPlay > 0 ? '' : 'disabled'"
                        @click="playHandler(currentPlay - 1)"
                    >上一集</span>
                    <i v-if="currentPlay > 0">{{movieInfo.filmurl[currentPlay - 1].split('wuyao')[0]}}</i>
                </div>
                <div>
                    <i v-if="currentPlay < movieInfo.filmurl.length - 1">{{movieInfo.filmurl[currentPlay + 1].split('wuyao')[0]}}</i>
                    <span
                        :class="currentPlay < movieInfo.filmurl.length - 1 ? '' : 'disabled'"
                        @click="playHandler(currentPlay + 1)"
                    >下一集</span>
                </div>
            </div>
            <span
                v-for='(item,index) in jxUrl'
                :class="index==jxActive?'active':''"
                :key="index"
                @click="changeXianlu(index)"
            >线路·0{{index+1}}</span>
        </div>
        <div class="juji">
            <div class="tip">点击下方，选集播放</div>
            <span
                v-for="(item,index) in movieInfo.filmurl"
                :key="index"
                :class="{active:currentPlay==index,m3u8:item.split('wuyao')[1].includes('.m3u8'),mp4:item.split('wuyao')[1].includes('.mp4')}"
                @click="playHandler(index)"
            >{{item.split('wuyao')[0]}}</span>
        </div>
        <div class="introduction">
            <div>简介</div>
            <p v-html="movieInfo.filmcontent"></p>
        </div>
    </div>
</template>

<script>
import { getMovie, getJx } from "@/api/movie";
import Player from '@/components/ckplayer'
export default {
    data(){
        return{
            ckPlayer: false,
            movieInfo: {
                filmurl: []
            },
            currentPlay: -1,
            playUrl: '',
            jxShow: false,
            jxUrl: [],
            jxActive: 0,
            rotationTimer: 0,
            rotationShow: 1,
            rotationData:[
                `当前播放：`,
                '请不要相信视频中任何广告！',
                '手机浏览器观看，将浏览器UA标识设置为“电脑”，可有效防止广告弹窗！',
                '视频加载速度与网速有关，请耐心等待'
            ],
            tipShow: true,
            showType: ''
        }
    },
    components:{Player},
    created(){
        // 小屏幕强制横屏
        // if(window.screen.width < window.screen.height){
        //     let box = document.querySelector('.player div')
        //     let width = box.offsetWidth
        //     let height = box.offsetHeight
        //     console.log(box, width, height)
        //     box.style.width = height+'px'
        //     box.style.height = width+'px'
        //     box.style.transform = 'rotate(90deg)'
        //     box.style.transformOrigin = `${width/2}px ${width/2}px`
        // }
        // 判断页面打开类型
        if (this.$route.query.type === 'fullScreen') {
            window.hideHeader(true)
        } else {
            window.hideHeader(false)
        }
        // 获取localStorage
        let history = JSON.parse(window.localStorage.getItem('history')) || []
        let flag = history.findIndex(item=>item[1]==this.$route.query.id)
		let currentPlay = 0
        if(flag>-1) {
            currentPlay = history[flag][2] || 0
            this.jxActive = history[flag][3]
        }

        getJx().then(res=>{
            this.jxUrl = res.data[0].value.split(',')
        })

        getMovie({id: this.$route.query.id}).then(res=>{
            this.movieInfo = res.data.data[0]
            // 判断 是否为 抓取的数据
            console.log(this.movieInfo.sourcetype)
            if(this.movieInfo.sourcetype) this.jxShow = true
            
            this.movieInfo.filmurl = this.movieInfo.filmurl.replace(/<\/li><li>/g,',').replace(/<li>/g, '').replace(/<\/li>/g, '').split(',')
            // 数据库的数据 需要倒置
            if(!this.jxShow) this.movieInfo.filmurl.reverse()

            document.title = this.movieInfo.name+'-五妖影院'
            this.playHandler(currentPlay)
        })
    },
    methods:{
        playHandler(i){ // 切换 选集
            if(this.currentPlay == i || i < 0 || i >= this.movieInfo.filmurl.length) return;
            this.currentPlay = i
            let url = this.movieInfo.filmurl[i].split('wuyao')[1]
            if (url.includes('.mp4')) {
                this.ckPlayer = true
				url = url.substring(0, url.indexOf('.mp4')) + '.mp4'
            } else if(url.includes('.m3u8')) {
				url = url.substring(0, url.indexOf('.m3u8')) + '.m3u8'
				this.ckPlayer = true
			} else {
                this.ckPlayer = false
            }
            this.playUrl = this.jxShow ? this.jxUrl[this.jxActive] + url : url,
            this.rotationData[0] = `当前播放：${this.movieInfo.name} - ${this.movieInfo.filmurl[this.currentPlay].split('wuyao')[0]}`
            this.saveHistory()
			this.rotation()
        },
        changeXianlu(i){ // 切换 路线
            this.jxActive = i
            this.rotationData[0] = `当前播放：${this.movieInfo.name} - ${this.movieInfo.filmurl[this.currentPlay].split('wuyao')[0]}`
			let url = this.movieInfo.filmurl[this.currentPlay].split('wuyao')[1]
            this.playUrl = this.jxUrl[this.jxActive] + url
			if (url.includes('.mp4')) {
				url = url.substring(0, url.indexOf('.mp4')) + '.mp4'
			} else if(url.includes('.m3u8')) {
				url = url.substring(0, url.indexOf('.m3u8')) + '.m3u8'
			}
            this.saveHistory()
        },
        rotation(){ // 文字轮播 方法
            this.rotationTimer = setInterval(()=>{
                document.querySelector('.top-info ul').style.transition ='margin 0.8s ease'
                document.querySelector('.top-info ul').style.marginTop ='-24px'
                setTimeout(() => {
                    document.querySelector('.top-info ul').style.marginTop ='0px'
                    document.querySelectorAll('.top-info ul li')[0].innerText = this.rotationData[this.rotationShow]
                    document.querySelector('.top-info ul').style.transition ='none'
                    this.rotationShow = this.rotationShow < this.rotationData.length-1 ? this.rotationShow+1 : 0
                    document.querySelectorAll('.top-info ul li')[1].innerText = this.rotationData[this.rotationShow]
                }, 800);
            }, 3000)
        },
        saveHistory(){
            let info = [this.movieInfo.name, this.$route.query.id, this.currentPlay,this.jxActive]
            let history = JSON.parse(window.localStorage.getItem('history')) || []
            let flag = history.findIndex(item=>item[1] == info[1])
            if(flag > -1 && info) {
                history[flag] = info
            }else{
                if(history.length==4) history.pop()
                info && history.unshift(info)
            }
            window.localStorage.setItem('history',JSON.stringify(history))
            this.$bus.$emit('getHistory')
        }
    },
    beforeDestroy(){
        clearInterval(this.rotationTimer)
    }
}
</script>

<style lang="stylus" scoped>
    .play
        padding 10px 20px
        .title
            padding-top 20px
            font-size 18px
            font-weight 700
            opacity 0.8
        .player
            position relative
            margin 20px auto 22px
            height 50vw
            max-height 600px
            background #000000
            overflow hidden
            .frame-box
                position relative
                height 100%
                iframe
                    width 100%
                    height 100%
            .top-info
                position absolute
                top 0px
                padding 0 10px
                width 100%
                height 22px
                overflow hidden
                background #ffffff66
                color #ffffff88
                z-index 9
                .top-close
                    position absolute
                    top 0px
                    right 10px
                    cursor pointer
                ul
                    margin 0
                    padding 0
                    li
                        list-style none
        .xianlu
            position relative
            text-align center
            font-size 14px
            span
                display inline-block
                margin 4px 6px
                padding 2px 4px
                border 1px solid
                border-radius 4px
                filter invert(0%)
                cursor pointer
                &:hover,&.active
                    // filter invert(35%)
                    background-color #37ce4488!important
            .controller
                position absolute
                bottom 34px
                width 100%
                display flex
                justify-content space-between
                font-size 12px
                opacity .6
                &>div
                    width 50%
                    display flex
                    justify-content start
                    align-items center
                    span
                        display inline-block
                        margin 0px 0px
                        padding 0px 4px
                        border 1px solid
                        border-radius 4px
                        filter invert(0%)
                        cursor pointer
                        text-align center
                        &:hover
                            // filter invert(35%)
                            background-color #37ce4488!important
                        &.disabled
                            background-color  #555555!important
                            color #999999
                            cursor no-drop
                    i
                        width calc(100% - 60px)
                        margin 0 4px
                        display inline-block
                        overflow hidden
                        text-overflow ellipsis
                        white-space nowrap
                        text-align left
                    &:last-child
                        justify-content end
                        i
                            text-align right
        .juji
            .tip
                border-left 4px solid
                padding-left 6px
                margin-bottom 10px
            span
                padding 3px 6px
                max-width 315px
                margin 10px
                display inline-block
                border 1px solid
                border-radius 4px
                opacity 1
                cursor pointer
                &:hover,&.active
                    // opacity 0.5
                    color #087f12d4!important
                &.m3u8
                    color #0d675a
                &.mp4
                    color #582f0c
        .introduction
            padding 20px 0
            > div
                border-left 4px solid
                padding-left 6px
                margin-bottom 10px
            > p
                font-size 14px
                opacity 0.9
</style>