import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueBus from 'vue-bus'
import $ from 'jquery'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import bootstrap from 'bootstrap'
import VueLazyload from "vue-lazyload"
 
Vue.use(VueLazyload, {
  error: './images/error.png',
  loading: './images/loading.gif',
  throttleWait: 800 // 节流阀
})
Vue.use(VueBus);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
