import axios from "./axios";

// 获取影片 
export const getMovie = (params) => { 
    return axios({
        url:'/web/movie',
        method: 'get',
        params: params
    })
};

// 获取解析URL
export const getJx = () => { 
    return axios({
        url:'/web/jx',
        method: 'get'
    })
};