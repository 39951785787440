<template>
  <div id="app" :class="{dark: light}">
    <div v-if="!hideHeader" class="container-sm m-shadow m-header">
      <m-header></m-header>
      <!-- 回到顶部 -->
    </div>
    <go-top></go-top>
    <plug-in :light.sync = light></plug-in>
    <div class="container-sm">
      <router-view />
    </div>
  </div>
</template>

<script>
import "@/assets/fonts/iconfont.css"
import MHeader from '@/view/header'
import GoTop from '@/components/goTop'
import plugIn from '@/components/plug-in'

export default {
  name: 'App',
  components: {
    MHeader,
    GoTop,
    plugIn
  },
  data(){
    return {
      light: true,
      hideHeader: false
    }
  },
  watch: {
    light: {
      immediate: true,
      handler(x) {
        console.log(x)
        window.sessionStorage.setItem('light', x)
      }
    }
  },
  created() {
    this.light = new Date().getHours()<7 || new Date().getHours()>18
    window.hideHeader = (value) => {
      this.hideHeader = value
    }
  },
  methods:{
  }
}
</script>

<style lang="stylus">
  #app {
    font-family Avenir, Helvetica, Arial, sans-serif
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    overflow hidden
    color #000000
    background #eeeeee
    min-height 100vh
    transition background 1.5s
  }
  #app .m-shadow{
    box-shadow 0 2px 4px 1px #66666699
    overflow hidden
  }
  #app.dark{
    color #eeeeee
    background #222222
    transition background 1.5s
    .m-shadow {
      box-shadow 0 2px 4px 1px #eeeeee99
    }
  }
  #app .container-sm{
    padding 0
    margin-bottom 30px
  }
  .m-header{
    overflow unset!important
  }
  .card {
    margin 30px auto
    background transparent!important
  }
</style>
