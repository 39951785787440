<template>
  <div class="m-video" style="width: 100%;height: 100%;"></div>
</template>

<script>
export default {
    props: ['playurl'],
    data(){
        return{
            player:null,
            videoObject:{}
        }
    },
    created(){
        //定义一个变量：videoObject，用来做为视频初始化配置
        this.videoObject = {
            container: '.m-video', //“#”代表容器的ID，“.”或“”代表容器的class
            variable: 'player', //播放函数名称，该属性必需设置，值等于下面的new ckplayer()的对象
            debug:false,//开启调试模式
            html5m3u8: true,//PC平台上是否使用h5播放器播放m3u8
            video: this.playurl,//视频地址
            // autoplay: true
        };
    },
    watch:{
        playurl:{
            immediate: true,
            handler(x,y){
                if(x){
                    setTimeout(() => {
                        if(this.player){
                            this.player.newVideo({
                                autoplay: true,
                                video: x
                            })
                        }else{
                            this.player = new ckplayer(this.videoObject);//初始化播放器
                        }
                    }, 200);
                }
            }
        }
    }
}
</script>

<style>

</style>