<template>
    <div class="row">
        <div
            class="card col-lg-2 col-md-3 col-5"
            v-for="(item,index) in movieList"
            :key="index"
            @click="toPlay(item.id)"
            v-lazy-container="{ selector: 'img' }"
        >
            <!-- <div :style="'background-image:url('+item.filmpic+')'" class="card-img-top" :alt="item.name"></div> -->
            <div class="img-box">
                <img :data-src="item.filmpic + '?_=' + new Date() / 1" class="card-img-top" :alt="item.name" />
            </div>
            <div class="card-body m-info">
                <div class="info">
                    <span class="card-text">{{item.name}}</span>
                    <span class="year">{{item.releasedate}}</span>
                </div>
                <div class="star">{{item.filmstarring}}/</div>
            </div>
            <div class="note">{{item.note}}</div>
            <div class="m-mask">
                <i class="iconfont icon-play"></i>
            </div>
        </div>
        <div class="card col-lg-2 col-md-3 col-5 fillbox" v-for="(item,index) in fillArr" :key="999+index">
        </div>
    </div>
</template>

<script>
export default {
    props: ['movieList'],
    data(){
        return{
            fillArr: [],
            positionDealing: false // 判断计算图片位置是否正在处理中
        }
    },
    created(){
        this.fillBlock()
        window.onresize = () => {
            this.fillBlock()
        }
        window.onscroll = (e) => {
            // const top = window.scrollY
            if (this.positionDealing) return
            this.positionDealing = true
            const height = window.screen.availHeight
            this.movePosition(height)
        }
    },
    methods:{
        toPlay(id){
            this.$router.push({path:'/play', query: {id}})
        },
        // 计算列表末尾需要填充的数量
        fillBlock() {
            let width = window.screen.width
            let rowNum = 2
            if(width > 992){
                rowNum = 5
            }else if(width > 768){
                rowNum = 3
            }else{
                rowNum = 2
            }
            let num = this.movieList % rowNum
            if (rowNum - num) {
                this.fillArr = new Array(rowNum - num).fill(1)
            }
        },
        // 每次滚动，都处理一下当前屏幕内的列表图片居中显示
        movePosition(height){
            const imgs = document.querySelectorAll('.card-img-top')
            imgs.forEach((ele, i) => {
                const top = ele.getBoundingClientRect().top
                if (top > 0 && top < height) {
                    const offsetHeight = ele.offsetHeight
                    let mg = (280 - offsetHeight) / 2
                    mg = mg > 0 ? mg : 0
                    const marginTop = ele.style.marginTop
                    if (marginTop !== mg + 'px') {
                        ele.style.margin = `${mg}px 0`
                    }
                }
            })
            this.positionDealing = false
        }
    }
}
</script>

<style lang="stylus" scoped>
    .row
        justify-content center
        >.col-lg-2
            position relative
            // height 290px
            margin 10px 12px
            padding 0
            cursor pointer
            &:hover
                box-shadow 0 2px 2px 1px #00000022
                .m-mask
                    display block
            .img-box
                // position relative
                height 280px
                overflow hidden
                background-image radial-gradient(circle,#ddd, #666)
            .card-img-top
                // max-width 186px
                // height 220px
                // background-position bottom
                // background-repeat no-repeat
                // background-size cover
            // .img-move
            //     position absolute
            //     top 50%
            //     left 50%
            //     transform translate(-50%, -50%)
            >.card-body
                padding 10px
            .note
                position absolute
                top 6px
                right 2px
                padding 0px 2px 0px 4px
                font-size 10px
                text-align center
                color #dddddd
                background-color #000000bb
            .m-mask
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                display none
                background #00000088
                i 
                    position absolute
                    top 50%
                    left 50%
                    transform translate(-40%,-90%)
                    font-size 55px
                    color #eeeeee
        .fillbox
            border none
            pointer-events none
    .m-info
        padding 4px!important
        // height 50px
        overflow hidden
        display flex
        flex-direction column
        justify-content flex-end
        .info
            display flex
            .card-text
                flex 1
                overflow hidden
                white-space nowrap
                text-overflow ellipsis
            .year
                width 40px
                opacity .6
        .star
            opacity .4
            font-size 14px
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
</style>